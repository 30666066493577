// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-showcase-js": () => import("/opt/build/repo/src/templates/showcase.js" /* webpackChunkName: "component---src-templates-showcase-js" */),
  "component---src-pages-brand-js": () => import("/opt/build/repo/src/pages/brand.js" /* webpackChunkName: "component---src-pages-brand-js" */),
  "component---src-templates-tag-list-js": () => import("/opt/build/repo/src/templates/tagList.js" /* webpackChunkName: "component---src-templates-tag-list-js" */),
  "component---src-templates-markdown-post-js": () => import("/opt/build/repo/src/templates/markdown/post.js" /* webpackChunkName: "component---src-templates-markdown-post-js" */),
  "component---src-templates-tag-match-js": () => import("/opt/build/repo/src/templates/tagMatch.js" /* webpackChunkName: "component---src-templates-tag-match-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

